import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import title_image from '../../images/titles/enterprise-collaboration.jpg'

const pageTitle = "Enterprise Collaboration";
const pageSlug = "enterprise-collaboration";


class EnterpriseCollaboration extends React.Component{
    render(){
      return(
        <Layout>

<section className="section page-title" style={{ backgroundImage: `url(${title_image})`}}>
      <div className="overlay"></div>
        <div className="container">
            <h1 className="title">{pageTitle}</h1>
            <h6 className="subtitle">Accelerate teamwork in your workplace without any resources or other dependencies.</h6>
        </div>
</section>

<section id="p_technology_services" className="section static-page page-content">
        <div className="container">
            <div className="columns">
                <div className="column is-12 content-area">
                    
                  <h3>' Work ' has changed tremendously, and how we interpret it has led many businesses to invest in innovative collaborative approaches.</h3>
                  <p>Over time, solutions for business collaboration have developed considerably to improve productivity and ensure security. Although it takes a lot more than simply selecting and introducing the right technology to revolutionize the workplace. The approach must have an effect on implementation and change management in addition to the existing technological configuration of devices. As well as organizational governance to ensure users are able to implement the things needed to manage the new technology. Having a strong structure and a robust plan can provide the perfect starting point to modernize the organization so that your team members can work better together.</p>
                  <span className="break"></span>
                  <span className="break"></span>
                  <h4> Our Enterprise Collaboration Services </h4>
                  <p>Our experts can provide the ideal platform for strengthening the organization's employee engagement, collaboration, and productivity. Using the following information:</p>
                  <ul>
                    <li>Modern Workplace Deployments</li>
                    <li>Skills Development & Training</li>
                    <li>Digital Workplace Deployments & Support</li>
                    <li>User account control</li>
                    <li>Intranet Modernization</li>
                    <li>Skills Development & Training</li>
                    <li>Security & Compliance Checks</li>
                    <li>Content Migration</li>
                    <li>Content Management</li>
                    <li>Cloud Service Adoption & Support</li>
                    <li>Data Classification</li>
                    <li>Proactive reports</li>
                    <li>Workflow Improvement</li>
                    <li>Data classification</li>
                  </ul>
                    
                    
                </div>
            </div>
        </div>
      </section>



</Layout>
    )
  }
}

export default EnterpriseCollaboration
